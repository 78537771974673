<template>
  <div class="column fit q-pa-md">
    <MapboxMap @load="onMapLoaded">
      <MapboxClusters :markers="markersCollection" :colors="['#91b34f', '#82a145','#657d36']">
        <template slot-scope="marker">
          <h3>{{marker.name}}</h3>
        </template>
      </MapboxClusters>

      <MapboxControls />
      <MapboxScale />
    </MapboxMap>
  </div>
</template>

<style>
</style>

<script>
import { Loading } from 'quasar'
import { MapboxMap, MapboxClusters, MapboxControls, MapboxScale } from '../../components/mapbox'
import { mapGetters } from 'vuex'

export default {
  name: 'CiblesMap',
  components: { MapboxMap, MapboxClusters, MapboxControls, MapboxScale },

  data () {
    return {
      mapLoaded: false
    }
  },

  computed: {
    ...mapGetters({
      mapCibles: 'cibles/getMapCibles',
      filters: 'cibles/getFilters'
    }),

    markersCollection () {
      let features = []
      this.mapCibles.forEach(cible => {
        cible.cibles.forEach(c => {
          let wallet

          if (c.agents.length) {
            if (c.agents[0].entities && c.agents[0].entities.length) {
              wallet = c.agents[0].entities[0].wallets[0]
            }
          } else if (c.entities.length) {
            wallet = c.entities[0].wallets[0]
          } else if (c.wallets.length) {
            wallet = c.wallets[0]
          }

          if (wallet) {
            features.push({
              'type': 'Feature',
              'geometry': {
                'type': 'Point',
                'coordinates': [Number(wallet.LNG), Number(wallet.LAT)]
              },
              'properties': {
                'id': cible.id,
                'label': cible.label,
                'to': { name: 'cible-show', params: { id: c.groupe_cible } },
                'marker-symbol': 'monument'
              }
            })
          }
        })
      })

      return { 'type': 'FeatureCollection', features }
    }
  },

  watch: {
    filters: {
      handler (newVal, oldVal) {
        if (this.mapLoaded) this.fetchMapCibles()
      },
      deep: true
    }
  },

  mounted () {
    Loading.show()
  },

  methods: {
    onMapLoaded () {
      this.mapLoaded = true
      Loading.hide()

      this.fetchMapCibles()
    },

    fetchMapCibles () {
      let params = { rowsPerPage: 0 }
      this.filters.forEach(filter => { params[filter.id] = filter.value })

      Loading.show()
      this.$store.dispatch('cibles/fetchMapCibles', params)
        .finally(() => { Loading.hide() })
    }
  }
}
</script>
